import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import CustomButton from 'components/CustomButton'
import Popup from 'components/Popup'
import { useAuth } from 'hooks/useAuth'
import useQuery from 'hooks/useQuery'

export default function SignUp() {
  const { state } = useLocation()
  const { push } = useHistory()
  const { spacing, palette } = useTheme()
  const [policyChecked, setPolicyChecked] = useState(false)
  const [optinChecked, setOptinChecked] = useState(true)
  const [error, setError] = useState(null)
  const { signUp } = useAuth()
  const query = useQuery()

  if (!state) {
    push('/login')
    return null
  }

  const handleClick = () => {
    signUp({ ...state, optin_promotion_mail: optinChecked })
      .then(() => {
        const path = Boolean(query.continue) ? decodeURIComponent(query.continue) : '/'
        push(path)
      })
      .catch(setError)
  }

  return (
    <div
      style={{
        padding: spacing(6, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2">Runtripアカウントでログインしました。</Typography>
      <Typography variant="body2">この情報でサービスを利用しますか？</Typography>
      <Typography style={{ margin: spacing(6, 0), fontWeight: 'bold' }} variant="body1">
        {state.user_name}
      </Typography>
      <div style={{ maxWidth: 340, marginBottom: spacing(3) }}>
        <FormControlLabel
          style={{ marginBottom: spacing(3) }}
          control={
            <Checkbox
              color="primary"
              checked={policyChecked}
              onChange={e => setPolicyChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="caption">
              賞品等提供のため、エントリーに使用した個人情報を主催者および協賛者に提供することに同意する（主催者および協賛者における個人情報保護方針等については、各社のホームページよりご確認ください）
            </Typography>
          }
        />
        <FormControlLabel
          style={{ marginBottom: spacing(3) }}
          control={
            <Checkbox
              color="primary"
              checked={optinChecked}
              onChange={e => setOptinChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="caption">
              主催者および協賛者から、お得なお知らせを受け取る
            </Typography>
          }
        />
        <Typography
          style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
          align="center"
          variant="caption"
          component="p"
          onClick={() => window.open(process.env.REACT_APP_SPONCER_URL)}
        >
          主催者および協賛者はこちら
        </Typography>
      </div>
      <CustomButton style={{ width: 210 }} onClick={handleClick} disabled={!policyChecked}>
        利用して始める
      </CustomButton>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title="エラーが発生しました"
        description="会員登録に失敗しました。"
        primaryText="OK"
        onClickPrimary={() => setError(null)}
      />
    </div>
  )
}
